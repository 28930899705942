import { getProjectKeyFromRole, user, token } from './useAuth'
import { ref, watch } from 'vue'
import api from '@/api'
import { useStorage } from '@vueuse/core'

export const projectKey = useStorage('project.last_selected.key', '')
export const project = ref()
export const projectLoading = ref(false)

watch([token, projectKey], async ([newToken, newKey]) => {
  if (!newToken || !newKey) return
  projectLoading.value = true
  api.projects
    .for(newKey)
    .get()
    .then(({ data }) => (project.value = data))
    .finally(() => (projectLoading.value = false))
})

let initPromise = undefined

export const init = async () => {
  if (initPromise) return initPromise
  initPromise = new Promise(resolve => {
    if (!projectKey.value) {
      let pKey =
        projectKey.value ??
        getProjectKeyFromRole(user.value.roles?.find(it => !!getProjectKeyFromRole(it)))
      if (pKey) {
        projectKey.value = pKey
        return resolve()
      }
      api.projects.get().then(({ data: { results } }) => {
        pKey = results?.[0]?.keyword
        projectKey.value = pKey
        resolve()
      })
    }
    resolve()
  })
  return initPromise
}
